var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('auth.login'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('auth.login-with'))+" ")]),_c('validation-observer',{directives:[{name:"default",rawName:"#default",value:({invalid: _vm.invalid}),expression:"{invalid}"}],ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"autofocus":"","name":"login-email","placeholder":"admin@demo.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),(errors[0])?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t(_vm.getError(errors[0])))+" ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{staticClass:"my-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('auth.password')))]),_c('b-link',{attrs:{"to":{name:'auth-forgot-password'}}},[_c('small',[_vm._v(_vm._s(_vm.$t('auth.lost-password')))])])],1),_c('validation-provider',{attrs:{"rules":{ required: true },"name":"error"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"admin"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors[0])?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t(_vm.getError(errors[0])))+" ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('auth.remember-me'))+" ")])],1),_c('b-button',{attrs:{"disabled":invalid,"block":"","type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('auth.login'))+" ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }