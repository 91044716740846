<template>
   <div class="auth-wrapper auth-v1 px-2">
	  <div class="auth-inner py-2">
		 <!-- Login v1 -->
		 <b-card class="mb-0">
			<b-link class="brand-logo">
			   <vuexy-logo />
			</b-link>

			<b-card-title class="mb-1">
			   {{ $t('auth.login') }}
			</b-card-title>
			<b-card-text class="mb-2">
			   {{ $t('auth.login-with') }}
			</b-card-text>

			<!-- form -->
			<validation-observer ref="loginForm"
								 v-slot="{ errors, invalid }"
								 #default="{invalid}">
			   <b-form class="auth-login-form mt-2"
					   @submit.prevent="login">

				  <!-- email -->
				  <b-form-group label="Email"
								label-for="email">
					 <validation-provider #default="{ errors }"
										  name="Email"
										  rules="required|email">
						<b-form-input id="email"
									  v-model="userEmail"
									  :state="errors.length > 0 ? false:null"
									  autofocus
									  name="login-email"
									  placeholder="admin@demo.com" />
						<b-form-invalid-feedback v-if="errors[0]">
						   {{ $t(getError(errors[0])) }}
						</b-form-invalid-feedback>
					 </validation-provider>
				  </b-form-group>

				  <!-- password -->
				  <b-form-group class="my-1">
					 <div class="d-flex justify-content-between">
						<label for="password"> {{ $t('auth.password') }}</label>
						<b-link :to="{name:'auth-forgot-password'}">
						   <small>{{ $t('auth.lost-password') }}</small>
						</b-link>
					 </div>
					 <validation-provider #default="{ errors }"
										  :rules="{ required: true }"
										  name="error">
						<b-input-group :class="errors.length > 0 ? 'is-invalid':null"
									   class="input-group-merge">
						   <b-form-input id="password"
										 v-model="password"
										 :state="errors.length > 0 ? false:null"
										 :type="passwordFieldType"
										 class="form-control-merge"
										 name="login-password"
										 placeholder="admin" />

						   <b-input-group-append is-text>
							  <feather-icon :icon="passwordToggleIcon"
											class="cursor-pointer"
											@click="togglePasswordVisibility" />
						   </b-input-group-append>
						</b-input-group>
						<b-form-invalid-feedback v-if="errors[0]">
						   {{ $t(getError(errors[0])) }}
						</b-form-invalid-feedback>
					 </validation-provider>
				  </b-form-group>

				  <!-- checkbox -->
				  <b-form-group class="mb-1">
					 <b-form-checkbox id="remember-me"
									  v-model="status"
									  name="checkbox-1">
						{{ $t('auth.remember-me') }}
					 </b-form-checkbox>
				  </b-form-group>

				  <!-- submit button -->
				  <b-button :disabled="invalid"
							block
							type="submit"
							variant="primary">
					 {{ $t('auth.login') }}
				  </b-button>
			   </b-form>
			</validation-observer>

		 </b-card>
		 <!-- /Login v1 -->
	  </div>
   </div>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
   BButton,
   BCard,
   BCardText,
   BCardTitle,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BInputGroup,
   BInputGroupAppend,
   BLink,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useJwt from "@/auth/jwt/useJwt";
import {getHomeRouteForLoggedInUser} from "@/auth/utils";
import {min} from "vee-validate/dist/rules";


extend("min", {
   ...min,
   message: 'max-six-number'
});


export default {


   name: 'Login',
   components: {
	  // BSV
	  BButton,
	  BForm,
	  BFormInput,
	  BFormGroup,
	  BCard,
	  BCardTitle,
	  BLink,
	  VuexyLogo,
	  BCardText,
	  BFormInvalidFeedback,
	  BInputGroup,
	  BInputGroupAppend,
	  BFormCheckbox,
	  ValidationProvider,
	  ValidationObserver,
   },
   mixins: [togglePasswordVisibility],
   data() {
	  return {
		 userEmail: '',
		 password: '',
		 status: '',
		 // validation rules
		 required,
		 email,
		 errors: null
	  }
   },
   computed: {
	  passwordToggleIcon() {
		 return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
	  },
   },
   methods: {
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },

	  async login() {
		 this.$refs.loginForm.validate().then(success => {
			if (success) {
			   useJwt.login({
				  email: this.userEmail,
				  password: this.password,
			   }).then(response => {
				  const userData = response.data
				  useJwt.setToken(response.data.access_token)
				  useJwt.setRefreshToken(response.data.access_token)
				  localStorage.setItem('userData', JSON.stringify(response.data))

				  this.$router.push(getHomeRouteForLoggedInUser(response.data.name))
					  .then(() => {

							   // this.$tours['myTour'].start()



						 this.$toast({
							component: ToastificationContent,
							position: 'top-right',
							props: {
							   title: `Καλώς ήρθατε ${userData.name || userData.username}`,
							   icon: 'CoffeeIcon',
							   variant: 'success',
							   text: `Έχετε συνδεθεί με επιτυχία.`,
							},
						 })
					  })
			   })
				   .catch(error => {

					  this.errors = error.response.data


					  this.$refs.loginForm.setErrors(this.errors)
				   })
			}
		 })


	  },

   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
